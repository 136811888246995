import { createTheme } from '@mui/material/styles';

const githubDarkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#0d1117',
    },
    text: {
      primary: '#c9d1d9',
    },
  },
});

export default githubDarkTheme;
