// src/App.js
import React, { useState, useEffect } from 'react';
import { ThemeProvider, CssBaseline, Container, Stack, Button, Typography, Box } from '@mui/material';
import NoteCard from './components/NoteCard';
import NoteInput from './components/NoteInput';
import darculaTheme from './themes/darcula';
import nordTheme from './themes/nord';
import githubDarkTheme from './themes/githubDark';
import whiteTheme from './themes/white';

const themes = {
  darcula: darculaTheme,
  nord: nordTheme,
  githubDark: githubDarkTheme,
  white: whiteTheme,
};

function App() {
  const [theme, setTheme] = useState('white');
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setTheme(savedTheme);
    }

    // Load saved notes from localStorage
    const savedNotes = localStorage.getItem('notes');
    if (savedNotes) {
      setNotes(JSON.parse(savedNotes));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  useEffect(() => {
    localStorage.setItem('notes', JSON.stringify(notes));
  }, [notes]);

  const handleAddNote = (note) => {
    setNotes([...notes, { text: note, imageUrl: '' }]);
  };

  const handleImageUpload = (index, imageUrl) => {
    const updatedNotes = [...notes];
    updatedNotes[index].imageUrl = imageUrl;
    setNotes(updatedNotes);
  };

  const handleDeleteNote = (index) => {
    setNotes(notes.filter((_, i) => i !== index));
  };

  const handleExportNotes = () => {
    const blob = new Blob([JSON.stringify(notes, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'notes_backup.json';
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleImportNotes = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const importedNotes = JSON.parse(e.target.result);
          setNotes(importedNotes);
        } catch (err) {
          alert('Error parsing JSON file.');
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <ThemeProvider theme={themes[theme]}>
      <CssBaseline />
      <Container>
        <Box textAlign="center" marginY={4}>
          <Typography variant="h4" component="div" gutterBottom>
            <span style={{ fontFamily: '"Comic Sans MS", cursive', fontWeight: 'bold' }}>dougie</span>
            <span role="img" aria-label="notebook">📒</span>
          </Typography>
          <Typography variant="subtitle1" component="div" gutterBottom>
            a simple and modern note-taking app
          </Typography>
        </Box>

        {/* Theme Buttons */}
        <Stack direction="row" spacing={1} justifyContent="center" marginY={2}>
          {Object.keys(themes).map((key) => (
            <Button
              key={key}
              onClick={() => setTheme(key)}
              variant={theme === key ? 'contained' : 'outlined'}
            >
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </Button>
          ))}
        </Stack>

        <NoteInput onAddNote={handleAddNote} />
        <Stack spacing={2} marginTop={2}>
          {notes.map((note, index) => (
            <NoteCard
              key={index}
              note={note.text}
              imageUrl={note.imageUrl}
              onImageUpload={(imageUrl) => handleImageUpload(index, imageUrl)}
              onDelete={() => handleDeleteNote(index)}
            />
          ))}
        </Stack>

        {/* Export and Import Buttons */}
        <Box textAlign="center" marginTop={4}>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button
              onClick={handleExportNotes}
              variant="contained"
              color="primary"
            >
              Export Notes
            </Button>
            <Button
              variant="contained"
              color="secondary"
              component="label"
            >
              Import Notes
              <input
                type="file"
                accept=".json"
                hidden
                onChange={handleImportNotes}
              />
            </Button>
          </Stack>
        </Box>

        {/* Social Media Buttons */}
        <Box
          position="fixed"
          bottom={0}
          right={0}
          p={2}
          bgcolor="background.paper"
          borderRadius="8px"
          boxShadow={3}
        >
          <Stack direction="row" spacing={1}>
            <Button
              href="https://github.com/1yc4n0rn0t"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48">
                <path fill="#2100c4" d="M24,4C12.954,4,4,12.954,4,24c0,8.887,5.801,16.411,13.82,19.016h12.36 C38.199,40.411,44,32.887,44,24C44,12.954,35.046,4,24,4z"></path>
                <path fill="#ddbaff" d="M37,23.5c0-2.897-0.875-4.966-2.355-6.424C35.591,15.394,34.339,12,34.339,12 c-2.5,0.5-4.367,1.5-5.609,2.376C27.262,14.115,25.671,14,24,14c-1.71,0-3.339,0.118-4.834,0.393 c-1.242-0.879-3.115-1.889-5.632-2.393c0,0-1.284,3.492-0.255,5.146C11.843,18.6,11,20.651,11,23.5 c0,6.122,3.879,8.578,9.209,9.274C19.466,33.647,19,34.764,19,36l0,0.305c-0.163,0.045-0.332,0.084-0.514,0.108 c-1.107,0.143-2.271,0-2.833-0.333c-0.562-0.333-1.229-1.083-1.729-1.813c-0.422-0.616-1.263-2.032-3.416-1.979 c-0.376-0.01-0.548,0.343-0.5,0.563c0.043,0.194,0.213,0.5,0.896,0.75c0.685,0.251,1.063,0.854,1.438,1.458 c0.418,0.674,0.417,2.468,2.562,3.416c1.53,0.677,2.988,0.594,4.097,0.327l0.001,3.199c0,0.639-0.585,1.125-1.191,1.013 C19.755,43.668,21.833,44,24,44c2.166,0,4.243-0.332,6.19-0.984C29.584,43.127,29,42.641,29,42.002L29,36 c0-1.236-0.466-2.353-1.209-3.226C33.121,32.078,37,29.622,37,23.5z"></path>
                <path fill="#ddbaff" d="M15,18l3.838-1.279c1.01-0.337,1.231-1.684,0.365-2.302l-0.037-0.026 c-2.399,0.44-4.445,1.291-5.888,2.753C13.596,17.658,14.129,18,15,18z"></path>
                <path fill="#ddbaff" d="M28.693,14.402c-0.878,0.623-0.655,1.987,0.366,2.327L32.872,18c0.913,0,1.461-0.37,1.773-0.924 c-1.46-1.438-3.513-2.274-5.915-2.701C28.717,14.384,28.705,14.393,28.693,14.402z"></path>
                <path fill="#ddbaff" d="M24,31c-1.525,0-2.874,0.697-3.791,1.774C21.409,32.931,22.681,33,24,33s2.591-0.069,3.791-0.226 C26.874,31.697,25.525,31,24,31z"></path>
              </svg>
            </Button>
            <Button
              href="https://discord.gg/SjjumAgj"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48">
                <path fill="#8c9eff" d="M42,45l-9-7.001L34,41H10c-2.761,0-5-2.238-5-5V10c0-2.762,2.239-5,5-5h27c2.762,0,5,2.238,5,5V45z"></path>
                <path fill="#fff" d="M32.59,16.24c0,0-2.6-2.01-5.68-2.24l-0.27,0.55c2.78,0.67,4.05,1.64,5.38,2.83 C29.73,16.21,27.46,15,23.5,15s-6.23,1.21-8.52,2.38c1.33-1.19,2.85-2.27,5.38-2.83L20.09,14c-3.23,0.31-5.68,2.24-5.68,2.24 S11.5,20.43,11,28.62c2.94,3.36,7.39,3.38,7.39,3.38l0.92-1.23c-1.57-0.54-3.36-1.51-4.9-3.27c1.84,1.38,4.61,2.5,9.09,2.5 s7.25-1.12,9.09-2.5c-1.54,1.76-3.33,2.73-4.9,3.27L28.61,32c0,0,4.45-0.02,7.39-3.38C35.5,20.43,32.59,16.24,32.59,16.24z M20,27 c-1.1,0-2-1.12-2-2.5s0.9-2.5,2-2.5s2,1.12,2,2.5S21.1,27,20,27z M27,27c-1.1,0-2-1.12-2-2.5s0.9-2.5,2-2.5s2,1.12,2,2.5 S28.1,27,27,27z"></path>
              </svg>
            </Button>
          </Stack>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default App;
