// src/components/ImageUpload.js
import React from 'react';
import { IconButton, Input } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

function ImageUpload({ onUpload }) {
  const handleChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      
      reader.onloadend = () => {
        onUpload(reader.result);
      };
      
      reader.readAsDataURL(file);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
      <label htmlFor="image-upload">
        <Input
          accept="image/*"
          id="image-upload"
          type="file"
          style={{ display: 'none' }}
          onChange={handleChange}
        />
        <IconButton color="primary" component="span">
          <PhotoCamera />
        </IconButton>
      </label>
    </div>
  );
}

export default ImageUpload;
