import { createTheme } from '@mui/material/styles';

const nordTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#2e3440',
    },
    text: {
      primary: '#d8dee9',
    },
  },
});

export default nordTheme;
