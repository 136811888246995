import React, { useState } from 'react';
import { Button, Box } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

function NoteInput({ onAddNote }) {
  const [note, setNote] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);

  const handleChange = (value) => {
    setNote(value);
  };

  const handleAdd = () => {
    if (note.trim()) {
      onAddNote(note);
      setNote('');
    }
  };

  return (
    <Box
      sx={{
        borderRadius: '12px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '16px',
        marginTop: '20px',
        backgroundColor: 'background.paper',
      }}
    >
      <ReactQuill
        value={note}
        onChange={handleChange}
        modules={NoteInput.modules}
        formats={NoteInput.formats}
        style={{
          marginBottom: '10px',
          height: isExpanded ? '500px' : '200px',
          transition: 'height 0.3s ease',
          borderRadius: '8px', // Rounded corners for the Quill editor
          border: '1px solid #ddd', // Light border for better visibility
        }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsExpanded(!isExpanded)}
          sx={{
            borderRadius: '20px',
            padding: '10px 20px',
          }}
        >
          {isExpanded ? 'Collapse' : 'Expand'}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAdd}
          sx={{
            borderRadius: '20px',
            padding: '10px 20px',
            marginLeft: '10px',
          }}
        >
          Add Note
        </Button>
      </Box>
    </Box>
  );
}

NoteInput.modules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    ['bold', 'italic', 'underline'],
    [{ 'color': [] }, { 'background': [] }],
    ['link'],
  ],
};

NoteInput.formats = [
  'header', 'font', 'list', 'bullet', 'bold', 'italic', 'underline', 'color', 'background', 'link'
];

export default NoteInput;
