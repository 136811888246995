// src/components/NoteCard.js
import React, { useState } from 'react';
import { Card, CardMedia, CardContent, Typography, IconButton, Modal, Box } from '@mui/material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ImageUpload from './ImageUpload';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DeleteIcon from '@mui/icons-material/Delete';

function NoteCard({ note, imageUrl, onImageUpload, onDelete }) {
  const [expanded, setExpanded] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleImageClick = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDeleteClick = () => {
    if (onDelete) onDelete();
  };

  const renderContent = () => {
    const codeBlockRegex = /```(\w+)\n([\s\S]*?)```/g;
    const parts = [];
    let lastIndex = 0;
    let match;

    while ((match = codeBlockRegex.exec(note)) !== null) {
      const [fullMatch, language, code] = match;

      if (match.index > lastIndex) {
        parts.push(
          <Typography key={`text-${lastIndex}`} variant="body1" sx={{ fontSize: '16px', color: 'text.primary', overflowWrap: 'break-word' }}>
            <span dangerouslySetInnerHTML={{ __html: note.substring(lastIndex, match.index) }} />
          </Typography>
        );
      }

      parts.push(
        <SyntaxHighlighter key={`code-${match.index}`} language={language} style={solarizedlight} customStyle={{ borderRadius: '8px', marginBottom: '10px' }}>
          {code}
        </SyntaxHighlighter>
      );

      lastIndex = match.index + fullMatch.length;
    }

    if (lastIndex < note.length) {
      parts.push(
        <Typography key={`text-${lastIndex}`} variant="body1" sx={{ fontSize: '16px', color: 'text.primary', overflowWrap: 'break-word' }}>
          <span dangerouslySetInnerHTML={{ __html: note.substring(lastIndex) }} />
        </Typography>
      );
    }

    return parts;
  };

  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: '16px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        padding: '10px',
        maxWidth: expanded ? '800px' : '600px',
        margin: '10px auto',
        backgroundColor: 'background.paper',
        transition: 'max-width 0.3s ease-in-out',
        position: 'relative',
      }}
    >
      {imageUrl && (
        <>
          <CardMedia
            component="img"
            height="140"
            image={imageUrl}
            alt="note image"
            sx={{ borderRadius: '16px', marginBottom: '10px', cursor: 'pointer' }}
            onClick={handleImageClick}
          />
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Box
              sx={{
                backgroundColor: 'background.paper',
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
                transition: 'opacity 0.3s ease-in-out',
                maxWidth: '80%',
                maxHeight: '80%',
                overflow: 'auto'
              }}
            >
              <img src={imageUrl} alt="note modal" style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />
            </Box>
          </Modal>
        </>
      )}
      <CardContent>
        {renderContent()}
      </CardContent>
      <ImageUpload onUpload={onImageUpload} />
      <IconButton
        onClick={handleExpandClick}
        sx={{
          position: 'absolute',
          bottom: '10px',
          right: '50px',
          color: 'text.primary',
        }}
      >
        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
      <IconButton
        onClick={handleDeleteClick}
        sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          color: 'error.main',
        }}
      >
        <DeleteIcon />
      </IconButton>
    </Card>
  );
}

export default NoteCard;
