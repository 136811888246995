import { createTheme } from '@mui/material/styles';

const darculaTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#2b2b2b',
    },
    text: {
      primary: '#ffffff',
    },
  },
});

export default darculaTheme;
